// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const geoLocationObj = {
	id: 'geoLocation',
	label: 'Geo Location Label',
	type: 'GEO',
	collapsed: false,
	values: [
		{
			value: ''
		}
	]
};

export const emptyInventoryObj = {
	pageInfo: {
		totalCount: 0,
		pageSize: 0,
		pageStart: 0,
		trackingData: []
	},
	inventory: [],
	incentives: {},
	accounts: {}
};
